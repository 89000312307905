import { Component, OnInit } from "@angular/core";
import { environment } from "@environments/environment";
import { ThemeService } from "@services/theme.service";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [AngularSvgIconModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent implements OnInit {
  discordLink = environment.websites.discord;
  theme!: string;
  constructor(private readonly themeService: ThemeService) {}
  ngOnInit(): void {
    this.themeService.theme$.subscribe((data: string) => (this.theme = data));
  }
}
