<section id="footer" class="w-full px-5">
  <div
    class="flex w-full border-t border-gray-light dark:border-gray-dark py-7 px-14 justify-between"
  >
    <div class="flex items-center gap-2.5">
      <span class="text-base text-black-1 dark:text-white-primary"
        >Powered by
      </span>
      <svg-icon
        [src]="
          this.theme === 'dark'
            ? 'assets/icons/secdojo-dark.svg'
            : 'assets/icons/secdojo.svg'
        "
      ></svg-icon>
    </div>
    <div class="flex">
      <p class="text-base text-black-1 dark:text-white-primary">
        2024 SecDojo. All rights reserved
      </p>
    </div>
    <div class="flex gap-8 items-center">
      <a [href]="discordLink" target="_blank">
        <!-- //discord -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="16"
          viewBox="0 0 23 16"
          fill="none"
        >
          <path
            d="M5.2528 12.4899C9.98691 14.3374 14.0156 14.6973 18.5811 11.8215C18.5476 11.8717 17.6445 13.2096 15.2697 13.8953C15.7714 14.581 16.4571 15.367 16.4571 15.367C17.979 15.367 19.4675 14.9322 20.7552 14.096C21.7586 13.427 22.3272 12.2731 22.2102 11.0689C22.0095 9.01185 21.5244 7.00495 20.7719 5.08172C19.8186 2.57311 17.5274 0.833776 14.8683 0.582896C14.6341 0.566164 14.4669 0.566164 14.3666 0.566164L14.099 0.833727C17.1595 1.70339 18.6814 3.05804 18.7149 3.10824C14.0154 0.733384 8.46296 0.69997 3.72999 3.00789C3.72999 3.00789 5.23517 1.51944 8.56325 0.733384L8.36257 0.5327C8.01134 0.5327 7.67685 0.566163 7.32568 0.61631C4.86722 1.03441 2.81017 2.72355 1.92378 5.0482C1.15445 7.0551 0.65274 9.16234 0.468787 11.303C0.368445 12.4403 0.903621 13.5608 1.84017 14.2131C3.07774 15.0325 4.54946 15.4674 6.03791 15.4674C6.03791 15.4674 6.63997 14.6813 7.24207 13.9789C4.98429 13.3099 4.06448 11.972 4.04775 11.9218L4.47265 12.1388C4.72666 12.2686 4.98707 12.3862 5.2528 12.4899ZM7.87759 11.0355C6.7905 11.002 5.93757 10.0822 5.97103 8.97839C6.0045 7.9415 6.8407 7.1053 7.87759 7.07183C8.96467 7.1053 9.81761 8.02511 9.78414 9.12893C9.73394 10.1659 8.91447 11.0021 7.87759 11.0355ZM14.701 11.0355C13.6139 11.002 12.761 10.0822 12.7945 8.97839C12.8279 7.9415 13.6641 7.1053 14.701 7.07183C15.7881 7.1053 16.641 8.02511 16.6076 9.12893C16.5742 10.1659 15.7379 11.0021 14.701 11.0355Z"
            [attr.fill]="this.theme === 'dark' ? '#fff' : '#3C4653'"
          />
        </svg>
      </a>
      <!-- youtube -->
      <a href="https://www.youtube.com/@secdojo4548" target="_blank"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
        >
          <path
            d="M15.3572 0.550934H4.12336C2.23718 0.550934 0.725464 2.09039 0.725464 3.9627V10.0373C0.725464 11.9373 2.26492 13.4491 4.13723 13.4491H15.3572C17.2573 13.4491 18.769 11.9096 18.769 10.0373V3.9627C18.769 2.09039 17.2295 0.550934 15.3572 0.550934ZM7.36869 9.77379V4.22621L12.1119 7L7.36869 9.77379Z"
            [attr.fill]="this.theme === 'dark' ? '#fff' : '#3C4653'"
          /></svg
      ></a>
      <!-- linkedin -->
      <a
        href="https://www.linkedin.com/company/secdojo/mycompany/verification/"
        target="_blank"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14.7306 0.362946H1.08137C0.632737 0.362946 0.268921 0.726762 0.268921 1.1754V14.8246C0.268921 15.2732 0.632737 15.637 1.08137 15.637H14.7306C15.1794 15.637 15.543 15.2732 15.543 14.8246V1.1754C15.543 0.726762 15.1794 0.362946 14.7306 0.362946ZM4.56094 14.0448H2.05079V5.96935H4.56078L4.56094 14.0448ZM3.30635 4.86555C2.50186 4.86555 1.8506 4.21348 1.8506 3.40997C1.85077 2.60759 2.50219 1.95535 3.30635 1.95535C4.10889 1.95535 4.76081 2.60726 4.76081 3.40997C4.76081 4.21348 4.10889 4.86555 3.30635 4.86555ZM13.9613 14.0446H11.4535V10.1177C11.4535 9.18114 11.4364 7.97643 10.1488 7.97643C8.84354 7.97643 8.64286 8.99655 8.64286 10.0496V14.0446H6.13466V5.96935H8.54212V7.07266H8.57576C8.91097 6.43781 9.72993 5.76835 10.9504 5.76835C13.4919 5.76835 13.9612 7.44103 13.9612 9.61499L13.9613 14.0446Z"
            [attr.fill]="this.theme === 'dark' ? '#fff' : '#3C4653'"
          /></svg
      ></a>
    </div>
  </div>
</section>
